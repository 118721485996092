import * as am5 from "@amcharts/amcharts5";
import { PieChart, PieSeries } from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { useEffect } from "react";
import "./DonutChart.module.scss";

interface DonutChartProps {
  chartId: string;
  data: { value: number; label: string }[];
  total: number;
}

const DonutChart = ({ chartId, data, total }: DonutChartProps) => {
  useEffect(() => {
    let root = am5.Root.new(chartId);

    root.setThemes([am5themes_Animated.new(root)]);

    let chart = root.container.children.push(
      PieChart.new(root, {
        startAngle: 60,
        endAngle: 300,
      })
    );

    let series = chart.series.push(
      PieSeries.new(root, {
        valueField: "value",
        categoryField: "label",
        startAngle: 160,
        endAngle: 380,
        radius: am5.percent(100),
        innerRadius: am5.percent(65),
      })
    );

    series.labels.template.set("visible", false);
    series.ticks.template.set("visible", false);

    let label = chart.seriesContainer.children.push(
      am5.Label.new(root, {
        textAlign: "center",
        centerY: am5.p50,
        centerX: am5.p50,
        text: `[fontSize:12px]Total[/]:\n[bold fontSize:20px]${Math.round(
          total
        )}[/]`,
      })
    );

    series.data.setAll(data);

    chart.seriesContainer.set("x", 120);

    return () => {
      root.dispose();
    };
  }, [chartId, data, total]);

  return (
    <div
      id={chartId}
      style={{ width: "100%", height: "200px", position: "relative" }}
    />
  );
};

export default DonutChart;
