import CustomTable from "components/common/CustomTable";
import MobileTable from "components/main/MobileTable";
import Modal from "components/onlineDataModal/Modal";
import { useEffect, useState } from "react";
import useMovieTop10Store from "stores/main/useMovieTop10Store";
import modifyTop10Data from "utils/modifyMovieData";
import Analysis from "../tableprops/AnalysisButton";
import MovieTitle from "../tableprops/MovieTitle";
import st from "./OnlineSection.module.scss";

const OnlineSection = () => {
  const [tableWidth, setTableWidth] = useState(1320);
  const { movieDatas } = useMovieTop10Store();
  const modifiedMovieDatas = movieDatas?.map((val) => {
    return modifyTop10Data(val);
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedMovieData, setSelectedMovieData] = useState<any>(null);

  // 모달을 열고, 전체 데이터를 전달
  const handleModalOpen = (rowData: any) => {
    setSelectedMovieData(rowData); // rowData 전체를 저장
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setSelectedMovieData(null);
  };

  // 모달 열릴 때 뒷배경 스크롤 막기
  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    // cleanup 함수
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isModalOpen]);

  const defaultColumns = [
    {
      key: "title",
      label: "영화명",
      isCustomCell: true,
      align: "left",
      fixed: true,
      flexGrow: window.innerWidth >= 1440 ? 2 : 1,
    },
    {
      key: "country",
      label: "국가",
      align: "center",
      flexGrow: 1,
    },
    {
      key: "ticketingPercent",
      label: "예매율",
      align: "center",
      flexGrow: 1,
    },
    {
      key: "watchedTotalCount",
      label: "관객수",
      align: "center",
      flexGrow: 1,
    },
    {
      key: "movieBuzzCount",
      label: "전체버즈량",
      align: "center",
      flexGrow: 1,
    },
    {
      key: "exposure",
      label: "노출도",
      align: "center",
      flexGrow: 1,
    },
    {
      key: "responsiveness",
      label: "반응도",
      align: "center",
      flexGrow: 1,
    },
    {
      key: "dataAnalysis",
      label: "데이터분석",
      isCustomCell: true,
      align: "center",
      flexGrow: 1,
      handleClick: handleModalOpen,
    },
  ];

  const handleResize = () => {
    const width = window.innerWidth;
    setTableWidth(width <= 1400 ? 1000 : 1320);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [tableWidth]);

  return (
    <div className={st.section_container}>
      <div className={st.title} data-aos="fade-up">
        <span>ONLINE DATA ANALYSIS</span>
        <p>온라인데이터 분석</p>
      </div>
      {window.innerWidth > 1024 && (
        <div className={st.table} data-aos="fade-up">
          <CustomTable
            rowKey={"grade"}
            columns={defaultColumns}
            datas={modifiedMovieDatas?.slice(0, 5)}
            width={tableWidth}
          >
            <MovieTitle key={"title"} text="" id={1} />
            <Analysis backColor="#F2F2F2" hoverEffect key={"dataAnalysis"} />
          </CustomTable>
        </div>
      )}
      {window.innerWidth <= 1024 && (
        <div data-aos="fade-up">
          <MobileTable
            datas={modifiedMovieDatas?.slice(0, 5)}
            handleModalOpen={handleModalOpen}
          />
        </div>
      )}
      {isModalOpen && (
        <Modal data={selectedMovieData} onClose={handleModalClose} />
      )}
    </div>
  );
};

export default OnlineSection;
