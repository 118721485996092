import { OnlineDatas } from "types/modal/onlineStatistics";
import st from "./ViralDataStatistics.module.scss";

interface ViralDataStatisticsProps extends OnlineDatas {
  totalAmount: number;
}

const ViralDataStatistics = ({
  sns,
  title,
  amount,
  bgColor,
  totalAmount,
}: ViralDataStatisticsProps) => {
  const percentage = totalAmount
    ? Math.round((Number(amount) / totalAmount) * 100)
    : 0;

  return (
    <div className={st.box}>
      <div>
        <span className={st.circle}>{sns}</span>
        <span className={st.data}>
          {title}
          <span>{Number(amount).toLocaleString()}</span>
        </span>
      </div>
      <div className={st.between}>
        <p>전체</p>
        <p>{percentage}%</p>
      </div>
      <div className={st.progressBar}>
        <div
          className={st.progress}
          style={{ width: `${percentage}%`, backgroundColor: bgColor }}
        ></div>
      </div>
    </div>
  );
};

export default ViralDataStatistics;
