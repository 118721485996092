import { ReactComponent as Check } from "assets/svg/checkIcon.svg";

import st from "components/analysisModal/ExpectedBox.module.scss";

interface Props {
  title: string;
  amount: string;
}

const ExpectedBox = ({ title, amount }: Props) => {
  return (
    <div className={st.box}>
      <p className={st.title}>
        <Check />
        <span>{title}</span>
      </p>
      <p className={st.amount}>{amount}</p>
    </div>
  );
};

export default ExpectedBox;
