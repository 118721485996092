import DonutChart from "components/analysisModal/DonutChart";
import st from "components/onlineDataModal/AIRecommand.module.scss";

interface Props {
  title: string;
  content: string;
  chartData: { value: number; label: string }[];
  chartId: string;
  total: number;
}

const AIRecommend = ({ title, content, chartData, chartId, total }: Props) => {
  const positiveData = chartData.find((data) => data.label === "긍정적");
  const negativeData = chartData.find((data) => data.label === "부정적");

  const positivePercent = positiveData ? positiveData.value.toFixed(0) : 0;
  const negativePercent = negativeData ? negativeData.value.toFixed(0) : 0;

  return (
    <div className={st.box}>
      <div>
        <DonutChart chartId={chartId} data={chartData} total={total} />
        <div className={st.between}>
          <span>
            <div className={st.blue_circle}></div>긍정적 반응 {positivePercent}%
          </span>
          <span>
            <div className={st.lilac_circle}></div>부정적 반응 {negativePercent}
            %
          </span>
        </div>
      </div>
      <div className={st.desc}>
        <h3 className={st.h3}>{title}</h3>
        <p className={st.p}>{content}</p>
      </div>
    </div>
  );
};

export default AIRecommend;
