import { ZineCardProps } from "types/main/zineCardProps";
import AnalysisButton from "pages/main/tableprops/AnalysisButton";
import st from "./ZineMovieCard.module.scss";

const ZineMovieCard = ({ data, openModal }: { data: ZineCardProps; openModal: (data: ZineCardProps) => void }) => {
  return (
    <div className={st.card_container}>
      <img src={data?.poster} alt={data?.title} /> 
      <div className={st.information}>
        <span className={st.title}>{data?.title}</span>
        <p className={st.content}>{data?.content}</p>
        <div className={st.button}>
          <AnalysisButton
            hoverEffect={false}
            width={167}
            height={44}
            borderColor="#111111"
            backColor="#ffffff"
            textColor="#111111"
            handleClick={() => openModal(data)} 
          />
        </div>
      </div>
    </div>
  );
};

export default ZineMovieCard;
