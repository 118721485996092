import { ReactComponent as Cancel } from "assets/svg/cancel.svg";
import { ReactComponent as Crown } from "assets/svg/crown.svg";
import { ReactComponent as OrangeCircle } from "assets/svg/orangeCircle.svg";
import st from "components/onlineDataModal/Modal.module.scss";
import { forwardRef, useEffect } from "react";
import AIMarketing from "./AIMarketing";
import ExpectAudience from "./ExpectAudience";
import OnlineData from "./OnlineData";
import ViralData from "./ViralData";

interface AnalysisModalProps {
  data: any;
  onClose: () => void;
}

const Modal = forwardRef<HTMLDialogElement, AnalysisModalProps>(
  ({ data, onClose }, ref) => {
    useEffect(() => {
      if (ref && typeof ref !== "function" && ref.current) {
        document.body.style.overflow = "hidden";
        return () => {
          document.body.style.overflow = "auto";
        };
      }
      return () => {};
    }, [ref]);

    return (
      <>
        <div className={st.modal_background} />
        <dialog className={st.dialog} ref={ref} open={!!data}>
          <div className={st.content}>
            <div className={st.between}>
              <p>
                MOVIEBOARD
                <span className={st.next}>by Sociallouder</span>
              </p>
              <Cancel className={st.cancle_btn} onClick={onClose} />
            </div>
            {data ? (
              <div className={st.section}>
                <div className={st.info_box}>
                  <div className={st.image_container}>
                    <img src={data.imageUrl} alt="영화 포스터" />
                    <OrangeCircle className={st.circle_icon} />
                    <span className={st.ranking_number}>
                      {data.grade ? `${data.grade}위` : "순위 없음"}
                    </span>
                  </div>
                  <div className={st.title_container}>
                    {data.title && <h1>{data.title}</h1>}
                    <ExpectAudience movieAudienceIdx={data.idx} />
                  </div>
                </div>
              </div>
            ) : (
              <p>영화 정보를 불러오고 있습니다...</p>
            )}
            <div className={st.subscriber_title}>
              <Crown className={st.crown_icon} />
              <p>구독전용</p>
            </div>
            <OnlineData movieBuzzIdx={data.idx} />
            <ViralData movieIdx={data.idx} />
            <AIMarketing movieIdx={data.idx} />
          </div>
        </dialog>
      </>
    );
  }
);

export default Modal;
