import useGetApi from "api/useGetApi";
import Graph from "components/analysisModal/Graph";
import { useEffect, useMemo } from "react";
import DataPercent from "./DataPercent";
import st from "./OnlineData.module.scss";

interface OnlineDataProps {
  movieBuzzIdx: number | undefined;
}

const OnlineData = ({ movieBuzzIdx }: OnlineDataProps) => {
  const { data: apiData, refetch } = useGetApi(
    `onlineData`,
    `/movie-top-ten/${movieBuzzIdx}/graph-data`,
    { enabled: !!movieBuzzIdx }
  );

  const buzzCounts = apiData?.buzzCounts || {
    portal: 0,
    community: 0,
    news: 0,
  };

  const totalBuzzCounts =
    buzzCounts.portal + buzzCounts.community + buzzCounts.news;

  const portalPercentage = totalBuzzCounts
    ? Math.round((buzzCounts.portal / totalBuzzCounts) * 100)
    : 0;
  const communityPercentage = totalBuzzCounts
    ? Math.round((buzzCounts.community / totalBuzzCounts) * 100)
    : 0;
  const newsPercentage = totalBuzzCounts
    ? Math.round((buzzCounts.news / totalBuzzCounts) * 100)
    : 0;

  const buzzDateCounts = apiData?.buzzDateCounts || {
    community: {},
    news: {},
    portal: {},
  };

  const buzzCountsDate = useMemo(() => {
    const dates = Object.keys(buzzDateCounts.community);
    return dates.map((date) => ({
      name: date,
      community: buzzDateCounts.community[date] || 0,
      news: buzzDateCounts.news[date] || 0,
      portal: buzzDateCounts.portal[date] || 0,
    }));
  }, [buzzDateCounts]);

  useEffect(() => {
    if (movieBuzzIdx) {
      refetch();
    }
  }, [movieBuzzIdx, refetch]);

  return (
    <div className={st.section}>
      <h3>온라인데이터 통계</h3>
      <div className={st.graph}>
        <Graph chartId="Graph" data={buzzCountsDate} />
      </div>
      <div className={st.online_data}>
        <DataPercent
          sns="P"
          title="(블로그/카페)"
          amount={buzzCounts.portal}
          percentage={`${portalPercentage}%`}
          totalAmount={totalBuzzCounts}
          bgColor="#CB70CA"
        />
        <DataPercent
          sns="C"
          title="커뮤니티(TOP5)"
          amount={buzzCounts.community}
          percentage={`${communityPercentage}%`}
          totalAmount={totalBuzzCounts}
          bgColor="#8878DA"
        />
        <DataPercent
          sns="N"
          title="NEWS(온라인)"
          amount={buzzCounts.news}
          percentage={`${newsPercentage}%`}
          totalAmount={totalBuzzCounts}
          bgColor="#84BADA"
        />
      </div>
    </div>
  );
};

export default OnlineData;
