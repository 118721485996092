import st from "components/analysisModal/DataStatistics.module.scss";
import { OnlineDatas } from "types/modal/onlineStatistics";

interface DataPercentProps extends OnlineDatas {
  totalAmount: number;
}

const DataPercent = ({
  sns,
  title,
  amount,
  bgColor,
  totalAmount,
}: DataPercentProps) => {
  const percentage = totalAmount
    ? Math.round((Number(amount) / totalAmount) * 100)
    : 0;

  return (
    <div className={st.box}>
      <div>
        <span className={st.circle} style={{ backgroundColor: bgColor }}>
          {sns}
        </span>
        <span className={st.data}>
          {title}
          <span>{amount.toLocaleString()}</span>
        </span>
      </div>
      <div className={st.between}>
        <p>전체</p>
        <p>{percentage}%</p>
      </div>
      <div className={st.progressBar}>
        <div
          className={st.progress}
          style={{ width: `${percentage}%`, backgroundColor: bgColor }}
        ></div>
      </div>
    </div>
  );
};

export default DataPercent;
