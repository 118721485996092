import useGetApi from "api/useGetApi";
import { ReactComponent as Crown } from "assets/svg/crown.svg";
import st from "components/analysisModal/SubscriptionOnly.module.scss";
import { useEffect, useMemo } from "react";
import AIMarketing from "./AIMarketing";
import DataStatistics from "./DataStatistics";
import Graph from "./Graph";
import ViralStatistics from "./ViralStatistics";

interface SubscriptionOnlyProps {
  movieIdx: number | undefined;
}

const SubscriptionOnly = ({ movieIdx }: SubscriptionOnlyProps) => {
  const { data: apiData, refetch } = useGetApi(
    "buzzCounts",
    `/movie-top-ten/${movieIdx}/graph-data`,
    {
      enabled: !!movieIdx,
    }
  );

  useEffect(() => {
    if (movieIdx) {
      refetch();
    }
  }, [movieIdx, refetch]);

  const buzzCounts = apiData?.buzzCounts || {
    portal: 0,
    community: 0,
    news: 0,
  };

  const totalBuzzCounts =
    buzzCounts.portal + buzzCounts.community + buzzCounts.news;

  const portalPercentage = totalBuzzCounts
    ? Math.round((buzzCounts.portal / totalBuzzCounts) * 100)
    : 0;
  const communityPercentage = totalBuzzCounts
    ? Math.round((buzzCounts.community / totalBuzzCounts) * 100)
    : 0;
  const newsPercentage = totalBuzzCounts
    ? Math.round((buzzCounts.news / totalBuzzCounts) * 100)
    : 0;

  const buzzDateCounts = apiData?.buzzDateCounts || {
    community: {},
    news: {},
    portal: {},
  };

  const buzzCountsDate = useMemo(() => {
    const dates = Object.keys(buzzDateCounts.community);
    return dates.map((date) => ({
      name: date,
      community: buzzDateCounts.community[date] || 0,
      news: buzzDateCounts.news[date] || 0,
      portal: buzzDateCounts.portal[date] || 0,
    }));
  }, [buzzDateCounts]);

  const statisticDatas = [
    {
      sns: "P",
      title: "(블로그/카페)",
      amount: buzzCounts.portal,
      percentage: `${portalPercentage}%`,
      bgColor: "#CB70CA",
    },
    {
      sns: "C",
      title: "커뮤니티(TOP5)",
      amount: buzzCounts.community,
      percentage: `${communityPercentage}%`,
      bgColor: "#8878DA",
    },
    {
      sns: "N",
      title: "NEWS(온라인)",
      amount: buzzCounts.news,
      percentage: `${newsPercentage}%`,
      bgColor: "#84BADA",
    },
  ];

  return (
    <div className={st.section}>
      <h2>
        <Crown className={st.crown_img} />
        구독전용
      </h2>
      <h3>온라인데이터 통계</h3>
      <div className={st.graph}>
        <Graph chartId="Graph" data={buzzCountsDate} />
      </div>
      <div className={st.boxes}>
        {statisticDatas.map((data, idx) => (
          <DataStatistics
            key={idx}
            sns={data.sns}
            title={data.title}
            amount={data.amount ? data.amount.toLocaleString() : "0"}
            percentage={data.percentage}
            bgColor={data.bgColor}
          />
        ))}
      </div>
      <ViralStatistics movieIdx={movieIdx} />
      <AIMarketing movieIdx={movieIdx} />
    </div>
  );
};

export default SubscriptionOnly;
