import Analysis from "pages/main/tableprops/AnalysisButton";
import { ModifiedMovieProps } from "types/main/movieProps";
import st from "./MobileTable.module.scss";

const MobileTable = ({
  datas,
  handleModalOpen,
}: {
  datas: ModifiedMovieProps[];
  handleModalOpen: (movie: ModifiedMovieProps) => void;
}) => {
  return (
    <div className={st.table_container}>
      {datas?.map((item, index) => (
        <div key={index} className={st.table_item}>
          <div className={st.title}>
            <span className={st.grade}>{item.grade}</span>
            <span className={st.movie_title}>{item.title}</span>
          </div>
          <table>
            <tbody>
              <tr>
                <th>국가</th>
                <td>{item.country}</td>
                <th>예매율</th>
                <td>{item.ticketingPercent}</td>
              </tr>
              <tr>
                <th>관객수</th>
                <td>{item.watchedTotalCount}</td>
                <th>전체버즈량</th>
                <td>{item.movieBuzzCount}</td>
              </tr>
              <tr>
                <th>노출도</th>
                <td>{item.exposure}</td>
                <th>반응도</th>
                <td>{item.responsiveness}</td>
              </tr>
            </tbody>
          </table>
          <div className={st.line} />
          <Analysis
            hoverEffect={false}
            width={150}
            height={45}
            textColor="#ffffff"
            handleClick={() => handleModalOpen(item)} // 모달 열기
          />
        </div>
      ))}
    </div>
  );
};

export default MobileTable;
