import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import {
  AxisRendererX,
  AxisRendererY,
  CategoryAxis,
  ColumnSeries,
  ValueAxis,
  XYChart,
  XYCursor,
} from "@amcharts/amcharts5/xy";
import { useEffect } from "react";

interface ChartData {
  name: string;
  value: number;
}

interface ColumnChartProps {
  chartId2: string;
  data: ChartData[];
}

const ColumnChart = ({ chartId2, data }: ColumnChartProps) => {
  useEffect(() => {
    let root = am5.Root.new(chartId2);

    root.setThemes([am5themes_Animated.new(root)]);

    let chart = root.container.children.push(
      XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: "none",
        wheelY: "none",
        paddingLeft: 0,
      })
    );

    let cursor = chart.set("cursor", XYCursor.new(root, {}));
    cursor.lineY.set("visible", false);

    let xRenderer = AxisRendererX.new(root, {
      minGridDistance: 30,
      minorGridEnabled: true,
    });

    let xAxis = chart.xAxes.push(
      CategoryAxis.new(root, {
        maxDeviation: 0,
        categoryField: "name",
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root, {}),
      })
    );

    xRenderer.grid.template.set("visible", false);

    let yRenderer = AxisRendererY.new(root, {});
    let yAxis = chart.yAxes.push(
      ValueAxis.new(root, {
        maxDeviation: 0,
        min: 0,
        extraMax: 0.1,
        renderer: yRenderer,
      })
    );

    yRenderer.grid.template.setAll({
      strokeDasharray: [2, 2],
    });

    let series = chart.series.push(
      ColumnSeries.new(root, {
        name: "Series 1",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "value",
        sequencedInterpolation: true,
        categoryXField: "name",
        tooltip: am5.Tooltip.new(root, { dy: -25, labelText: "{valueY}" }),
      })
    );

    series.columns.template.setAll({
      cornerRadiusTL: 5,
      cornerRadiusTR: 5,
      strokeOpacity: 0,
      width: am5.percent(50),
      maxWidth: 80,
    });

    // 컬럼 색상 어댑터 설정 (소셜 미디어별 색상 지정)
    series.columns.template.adapters.add("fill", (fill, target) => {
      const dataContext = target.dataItem?.dataContext as ChartData | undefined;
      const name = dataContext?.name;
      switch (name) {
        case "인스타그램":
          return am5.color(0xe4405f);
        case "트위터":
          return am5.color(0x55acee);
        case "유튜브":
          return am5.color(0xff0000);
        default:
          return fill;
      }
    });

    series.columns.template.adapters.add("stroke", (stroke, target) => {
      const dataContext = target.dataItem?.dataContext as ChartData | undefined;
      const name = dataContext?.name;
      switch (name) {
        case "인스타그램":
          return am5.color(0xe4405f);
        case "트위터":
          return am5.color(0x55acee);
        case "유튜브":
          return am5.color(0xff0000);
        default:
          return stroke;
      }
    });

    series.bullets.push(function () {
      return am5.Bullet.new(root, {
        locationY: 1,
        sprite: am5.Picture.new(root, {
          templateField: "bulletSettings",
          width: 50,
          height: 50,
          centerX: am5.p50,
          centerY: am5.p50,
          shadowColor: am5.color(0x000000),
          shadowBlur: 4,
          shadowOffsetX: 4,
          shadowOffsetY: 4,
          shadowOpacity: 0.6,
        }),
      });
    });

    xAxis.data.setAll(data);
    series.data.setAll(data);

    series.appear(1000);
    chart.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, [chartId2, data]);

  return <div id={chartId2} style={{ width: "100%", height: "500px" }} />;
};

export default ColumnChart;
