import { RefObject, useEffect, useRef, useState } from "react";
import { Autoplay, Scrollbar } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import DoingRoundIcon from "@rsuite/icons/DoingRound";
import useGetApi from "api/useGetApi";
import AICard from "components/main/AICard";
import useMovieTop10Store from "stores/main/useMovieTop10Store";
import useModalStore from "stores/modal/useModalStore";
import { MovieAICardProps, MovieAIProps } from "types/main/movieAIProps";
import makeAICardData from "utils/makeAICardData";
import { openModal } from "utils/openModal";

import "swiper/scss";
import "swiper/scss/scrollbar";
import st from "./AISection.module.scss";
import "./AISection.scss";

const AISection = ({
  targetRef,
}: {
  targetRef: RefObject<HTMLDivElement[]>;
}) => {
  const modalRef = useRef<HTMLDialogElement>(null);

  const [slidesPerView, setSlidesPerView] = useState<number>(4);
  const { data: aiData } = useGetApi(
    "aisection",
    "/movie-top-ten/chat-gpt/predictive"
  );
  const { data: top10Data, isLoading } = useGetApi(
    "mainpage",
    "/movie-top-ten/info"
  );
  const { movieDatas, setMovieDatas } = useMovieTop10Store();
  const { setIsModalOpen, setSelectedCardData } = useModalStore();

  const handleResize = () => {
    const width = window.innerWidth;
    switch (true) {
      case width >= 1700:
        setSlidesPerView(4);
        break;
      case width >= 1400:
        setSlidesPerView(3);
        break;
      case width >= 1024:
        setSlidesPerView(2);
        break;
      default:
        setSlidesPerView(1);
        break;
    }
  };

  const handleOpenModal = (cardData: MovieAICardProps) => {
    setSelectedCardData(cardData);
    setIsModalOpen(true);
    openModal(modalRef);
  };

  useEffect(() => {
    handleResize();
    setMovieDatas(top10Data);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [isLoading]);

  return (
    <div
      className={st.section_container}
      data-aos="fade-up"
      ref={(el: HTMLDivElement) => targetRef?.current?.fill(el, 1, 2)}
    >
      <div className={st.title_area}>
        <div className={st.title}>AI DATA ANALYSIS</div>
        <div className={st.subtitle}>
          7월 영화
          <br className={st.line_break} /> AI 데이터 분석
        </div>
      </div>
      <div className={st.swiper_container_ai}>
        <Swiper
          modules={[Scrollbar, Autoplay]}
          spaceBetween={20}
          autoHeight={true}
          slidesPerView={slidesPerView}
          scrollbar={{
            draggable: true,
          }}
          autoplay={{
            delay: 3000,
          }}
        >
          {aiData?.map((item: MovieAIProps, key: number) => {
            let cardData = movieDatas?.filter((movie) => item.movie === movie.title)[0]! ? makeAICardData(
              item,
              movieDatas?.filter((movie) => item.movie === movie.title)[0]!
            ) : undefined;

            return (
              cardData &&
              <SwiperSlide key={key}>
                {isLoading ? (
                  <div className={st.loading}>
                    <DoingRoundIcon className={st.spinner} />
                    <span>Loading</span>
                  </div>
                ) : (
                  <AICard data={cardData} onOpenModal={handleOpenModal} />
                )}
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
};
export default AISection;
