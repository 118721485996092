import useGetApi from "api/useGetApi";
import { ReactComponent as Instagram } from "assets/svg/instagram.svg";
import InstagramLogo from "assets/svg/instagramLogo.svg";
import { ReactComponent as Twitter } from "assets/svg/twitter.svg";
import TwitterLogo from "assets/svg/twitterLogo.svg";
import { ReactComponent as Youtube } from "assets/svg/youtube.svg";
import YoutubeLogo from "assets/svg/youtubeLogo.svg";
import st from "components/analysisModal/ViralStatistics.module.scss";
import ViralDataStatistics from "components/onlineDataModal/ViralDataStatistics";
import { useEffect, useMemo } from "react";
import ColumnChart from "./ColumnChart";

interface ExpectedAudienceProps {
  movieIdx: number | undefined;
}

const ViralStatistics = ({ movieIdx }: ExpectedAudienceProps) => {
  const {
    data: apiData,
    refetch,
    isLoading,
    isError,
  } = useGetApi("viralCounts", `/movie-top-ten/${movieIdx}/graph-data`, {
    enabled: !!movieIdx,
  });

  useEffect(() => {
    if (movieIdx) {
      refetch();
    }
  }, [movieIdx, refetch]);

  const viralCounts = useMemo(
    () => ({
      instagram: apiData?.viralCounts?.instagram ?? 0,
      twitter: apiData?.viralCounts?.twitter ?? 0,
      youtube: apiData?.viralCounts?.youtube ?? 0,
    }),
    [apiData]
  );

  const totalViralCounts = useMemo(
    () => viralCounts.instagram + viralCounts.twitter + viralCounts.youtube,
    [viralCounts]
  );

  const instagramPercentage = useMemo(
    () =>
      totalViralCounts
        ? Math.round((viralCounts.instagram / totalViralCounts) * 100)
        : 0,
    [totalViralCounts, viralCounts.instagram]
  );

  const twitterPercentage = useMemo(
    () =>
      totalViralCounts
        ? Math.round((viralCounts.twitter / totalViralCounts) * 100)
        : 0,
    [totalViralCounts, viralCounts.twitter]
  );

  const youtubePercentage = useMemo(
    () =>
      totalViralCounts
        ? Math.round((viralCounts.youtube / totalViralCounts) * 100)
        : 0,
    [totalViralCounts, viralCounts.youtube]
  );

  const chartData = useMemo(
    () => [
      {
        name: "인스타그램",
        value: viralCounts.instagram,
        bulletSettings: {
          src: InstagramLogo,
        },
      },
      {
        name: "트위터",
        value: viralCounts.twitter,
        bulletSettings: {
          src: TwitterLogo,
        },
      },
      {
        name: "유튜브",
        value: viralCounts.youtube,
        bulletSettings: {
          src: YoutubeLogo,
        },
      },
    ],
    [viralCounts]
  );

  const statisticDatas = useMemo(
    () => [
      {
        sns: <Instagram />,
        title: "인스타그램",
        amount: viralCounts.instagram,
        percentage: `${instagramPercentage}%`,
        bgColor: "#CB70CA",
      },
      {
        sns: <Twitter />,
        title: "트위터",
        amount: viralCounts.twitter,
        percentage: `${twitterPercentage}%`,
        bgColor: "#55ACEE",
      },
      {
        sns: <Youtube />,
        title: "유튜브",
        amount: viralCounts.youtube,
        percentage: `${youtubePercentage}%`,
        bgColor: "#FF0000",
      },
    ],
    [instagramPercentage, twitterPercentage, youtubePercentage, viralCounts]
  );

  return (
    <div className={st.section}>
      <h3>바이럴데이터 통계</h3>
      <div className={st.graph}>
        <ColumnChart chartId2="viralChart" data={chartData} />
      </div>
      <div className={st.boxes}>
        {statisticDatas.map((data, idx) => (
          <ViralDataStatistics
            key={idx}
            sns={data.sns}
            title={data.title}
            amount={data.amount ? data.amount.toLocaleString() : "0"}
            percentage={data.percentage}
            bgColor={data.bgColor}
            totalAmount={totalViralCounts}
          />
        ))}
      </div>
    </div>
  );
};

export default ViralStatistics;
