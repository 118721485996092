import useGetApi from "api/useGetApi";
import st from "components/onlineDataModal/AIMarketing.module.scss";
import { useEffect, useMemo } from "react";
import AIRecommend from "components/onlineDataModal/AIRecommand";

interface AIMarketingProps {
  movieIdx: number | undefined;
}

const AIMarketing = ({ movieIdx }: AIMarketingProps) => {
  const { data: apiData, refetch: refetchApiData } = useGetApi(
    `aiMarketingAnalysis`,
    `/movie-top-ten/movie-zine/${movieIdx}`,
    { enabled: !!movieIdx }
  );

  const { data: apiDataPercent, refetch: refetchApiDataPercent } = useGetApi(
    `aiMarketingAnalysisPercent`,
    `/movie-top-ten/movie-zine/${movieIdx}`,
    { enabled: !!movieIdx }
  );

  useEffect(() => {
    if (movieIdx) {
      refetchApiData();
      refetchApiDataPercent();
    }
  }, [movieIdx, refetchApiData, refetchApiDataPercent]);

  // 안전한 접근을 위한 함수
  const calculateChartData = (positive: number = 0, negative: number = 0) => {
    const total = positive + negative;
    if (total === 0) return { data: [], total: 0 };
    return {
      data: [
        { value: (positive / total) * 100, label: "긍정적" },
        { value: (negative / total) * 100, label: "부정적" },
      ],
      total,
    };
  };

 
  const chartData1 = useMemo(() => {
    const marketingCounts = apiDataPercent?.chatGptAnalysisPercentResponseDto?.marketingCounts || { positive: 0, negative: 0 };
    return calculateChartData(marketingCounts.positive, marketingCounts.negative);
  }, [apiDataPercent]);

 
  const chartData2 = useMemo(() => {
    const riskCounts = apiDataPercent?.chatGptAnalysisPercentResponseDto?.riskCounts || { positive: 0, negative: 0 };
    return calculateChartData(riskCounts.positive, riskCounts.negative);
  }, [apiDataPercent]);


  const chartData3 = useMemo(() => {
    const competitionCounts = apiDataPercent?.chatGptAnalysisPercentResponseDto?.competitionCounts || { movie: 0, competitor: 0 };
    const total = competitionCounts.movie + competitionCounts.competitor;
    const data = [
      { value: (competitionCounts.movie / total) * 100 || 0, label: "긍정적" },
      { value: (competitionCounts.competitor / total) * 100 || 0, label: "부정적" },
    ];
    return { data, total };
  }, [apiDataPercent]);

  return (
    <div className={st.section}>
      <h3 className={st.title}>AI 마케팅 분석 및 업무 추천</h3>

      {/* 마케팅 분석 */}
      <AIRecommend
        title="마케팅 분석"
        content={apiData?.chatGptAnalysis?.marketingAnswer || "마케팅 분석 결과가 없습니다."}
        chartData={chartData1.data}
        chartId="chart1"
        total={chartData1.total}
      />

      {/* 리스크 분석 */}
      <AIRecommend
        title="리스크 분석"
        content={apiData?.chatGptAnalysis?.riskAnswer || "리스크 분석 결과가 없습니다."}
        chartData={chartData2.data}
        chartId="chart2"
        total={chartData2.total}
      />

      {/* 경쟁 영화 대비 */}
      <AIRecommend
        title="경쟁 영화 대비"
        content={apiData?.chatGptAnalysis?.competitionAnswer || "경쟁 영화 분석 결과가 없습니다."}
        chartData={chartData3.data}
        chartId="chart3"
        total={chartData3.total}
      />
    </div>
  );
};

export default AIMarketing;
