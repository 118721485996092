import useGetApi from "api/useGetApi";
import { ReactComponent as Instagram } from "assets/svg/instagram.svg";
import { default as InstagramLogo } from "assets/svg/instagramLogo.svg";
import { ReactComponent as Twitter } from "assets/svg/twitter.svg";
import TwitterLogo from "assets/svg/twitterLogo.svg";
import { ReactComponent as Youtube } from "assets/svg/youtube.svg";
import YoutubeLogo from "assets/svg/youtubeLogo.svg";
import ColumnChart from "components/analysisModal/ColumnChart";
import ViralDataStatistics from "components/onlineDataModal/ViralDataStatistics";
import { useEffect, useState } from "react";
import st from "./ViralData.module.scss";

interface ViralDataProps {
  movieIdx: number;
}

const ViralData = ({ movieIdx }: ViralDataProps) => {
  const { data: apiData, refetch } = useGetApi(
    "viralCounts",
    `/movie-top-ten/${movieIdx}/graph-data`,
    {
      enabled: !!movieIdx,
    }
  );

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    if (movieIdx) {
      refetch();
    }

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [movieIdx, refetch]);

  const viralCounts = apiData?.viralCounts || {
    instagram: 0,
    twitter: 0,
    youtube: 0,
  };

  const totalViralCounts =
    viralCounts.instagram + viralCounts.twitter + viralCounts.youtube;

  // 그래프에 사용할 데이터
  const chartData = [
    {
      name: "인스타그램",
      value: viralCounts.instagram,
      bulletSettings: {
        src: isMobile ? null : InstagramLogo,
      },
    },
    {
      name: "트위터",
      value: viralCounts.twitter,
      bulletSettings: {
        src: isMobile ? null : TwitterLogo,
      },
    },
    {
      name: "유튜브",
      value: viralCounts.youtube,
      bulletSettings: {
        src: isMobile ? null : YoutubeLogo,
      },
    },
  ];

  const statisticDatas = [
    {
      sns: <Instagram />,
      title: "인스타그램",
      amount: viralCounts.instagram,
      percentage: totalViralCounts
        ? Math.round((viralCounts.instagram / totalViralCounts) * 100)
        : 0,
      bgColor: "#E4405F",
    },
    {
      sns: <Twitter />,
      title: "트위터",
      amount: viralCounts.twitter,
      percentage: totalViralCounts
        ? Math.round((viralCounts.twitter / totalViralCounts) * 100)
        : 0,
      bgColor: "#55ACEE",
    },
    {
      sns: <Youtube />,
      title: "유튜브",
      amount: viralCounts.youtube,
      percentage: totalViralCounts
        ? Math.round((viralCounts.youtube / totalViralCounts) * 100)
        : 0,
      bgColor: "#FF0000",
    },
  ];

  return (
    <div className={st.section}>
      <h3>바이럴데이터 통계</h3>
      <div className={st.graph}>
        <ColumnChart chartId2="viralChart" data={chartData} />
      </div>
      <div className={st.boxes}>
        {statisticDatas.map((data, idx) => (
          <ViralDataStatistics
            key={idx}
            sns={data.sns}
            title={data.title}
            amount={data.amount ? data.amount.toLocaleString() : "0"}
            percentage={`${data.percentage}%`}
            totalAmount={totalViralCounts}
            bgColor={data.bgColor}
          />
        ))}
      </div>
    </div>
  );
};

export default ViralData;
