import DoingRoundIcon from "@rsuite/icons/DoingRound";
import useGetApi from "api/useGetApi";
import { ReactComponent as OrangeCircle } from "assets/svg/orangeCircle.svg";
import st from "components/analysisModal/TopTenAnalysis.module.scss";
import { Fragment, useEffect, useState } from "react";
import { MovieAICardProps } from "types/main/movieAIProps";
import { MovieDatailDatas } from "types/modal/topTenAnalysis";
import { ReactComponent as Logo } from "../../assets/svg/movieboardLogo.svg";
import ExpectedAudience from "./ExpectedAudience";

interface TopTenAnalysisProps {
  initialSelectedMovie: MovieAICardProps;
  onMovieChange: (movie: MovieAICardProps) => void;
}

const TopTenAnalysis = ({
  initialSelectedMovie,
  onMovieChange,
}: TopTenAnalysisProps) => {
  const [selectedRow, setSelectedRow] = useState<number | null>(null);
  const [data, setData] = useState<MovieDatailDatas[]>([]);
  const [selectedMovie, setSelectedMovie] =
    useState<MovieAICardProps>(initialSelectedMovie);

  const { data: movieDetailDatas, isLoading } = useGetApi(
    "movieDetail",
    "/movie-top-ten/detail"
  );

  const handleClick = (index: number) => {
    setSelectedRow(index);
    const selectedMovieData = data[index];

    if (selectedMovieData) {
      setSelectedMovie(selectedMovieData);
      onMovieChange(selectedMovieData);
    }
  };

  useEffect(() => {
    if (movieDetailDatas) {
      setData(movieDetailDatas);

      const activeIndex = movieDetailDatas.findIndex(
        (item: MovieDatailDatas) => item.title === initialSelectedMovie.title
      );
      setSelectedRow(activeIndex !== -1 ? activeIndex : null);
      if (activeIndex !== -1) {
        setSelectedMovie(movieDetailDatas[activeIndex]);
      }
    }
  }, [movieDetailDatas, initialSelectedMovie]);

  return (
    <>
      <div className={st.section}>
        <h1>최신영화 TOP10 분석</h1>
        <div className={st.info_box}>
          <div className={st.image_container}>
            <img src={selectedMovie.imageUrl} alt="영화 포스터" />
            <OrangeCircle className={st.circle_icon} />
            <span className={st.ranking_number}>
              {selectedRow !== null ? selectedRow + 1 : ""}위
            </span>
          </div>

          {isLoading ? (
            <div className={st.loading_section}>
              <table>
                <thead>
                  <tr className={st.head_row}>
                    <th>영화명</th>
                    <th>노출도 합</th>
                    <th>수집일</th>
                    <th>노출평균</th>
                  </tr>
                </thead>
              </table>
              <div className={st.loading}>
                <Logo />
                <div className={st.box}>
                  <DoingRoundIcon className={st.spinner} />
                  <span className={st.desc}>Loading...</span>
                </div>
              </div>
            </div>
          ) : (
            <Fragment>
              <table>
                <thead>
                  <tr className={st.head_row}>
                    <th>영화명</th>
                    <th>노출도 합</th>
                    <th>수집일</th>
                    <th>노출평균</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item: MovieDatailDatas, index) => (
                    <tr
                      key={index}
                      onClick={() => handleClick(index)}
                      className={selectedRow === index ? st.selected_row : ""}
                    >
                      <td
                        className={
                          selectedRow === index ? st.selected_title : ""
                        }
                      >
                        <div className={st.title_text}>
                          <span
                            className={`${st.number_circle} ${
                              selectedRow === index ? st.active : ""
                            }`}
                          >
                            {index + 1}
                          </span>
                          <p className={st.text}>{item.title}</p>
                        </div>
                      </td>
                      <td>{item.impressionTotalCount.toLocaleString()}</td>
                      <td>{item.crawlingCount}</td>
                      <td>{item.impressionAverage.toLocaleString()}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Fragment>
          )}
        </div>
      </div>
      <ExpectedAudience movieIdx={selectedMovie.idx} />
    </>
  );
};

export default TopTenAnalysis;
