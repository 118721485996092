import useGetApi from "api/useGetApi";
import ExpectedBox from "components/onlineDataModal/ExpectBox";
import { useEffect } from "react";
import st from "./ExpectAudience.module.scss";

interface ExpectedAudienceProps {
  movieAudienceIdx: number | undefined;
}

const ExpectAudience = ({ movieAudienceIdx }: ExpectedAudienceProps) => {
  const {
    data: expectedDatas,
    refetch,
    error,
  } = useGetApi(
    `expectedAudience`,
    `/movie-top-ten/movie-zine/${movieAudienceIdx}`,
    { enabled: !!movieAudienceIdx }
  );

  useEffect(() => {
    if (movieAudienceIdx !== undefined) {
      refetch();
    }
  }, [movieAudienceIdx, refetch]);

  if (!expectedDatas || !expectedDatas.chatGptPredictive) {
    return <p>예상 관객 데이터가 없습니다.</p>;
  }

  const predictiveData = expectedDatas.chatGptPredictive;

  return (
    <div className={st.section}>
      <div key={predictiveData.idx}>
        <p>
          <span className={st.gray}>AI 예상 관객량</span>
        </p>
        <div className={st.boxes}>
          <ExpectedBox
            title={"최저 흥행 예상"}
            amount={
              predictiveData.lowestWatchedCount !== undefined
                ? predictiveData.lowestWatchedCount.toLocaleString() + " 이하"
                : "0 이하"
            }
          />
          <ExpectedBox
            title={"평균 흥행 예상"}
            amount={
              predictiveData.middleWatchedCount !== undefined
                ? predictiveData.middleWatchedCount.toLocaleString()
                : "0"
            }
          />
          <ExpectedBox
            title={"최고 흥행 예상"}
            amount={
              predictiveData.highestWatchedCount !== undefined
                ? predictiveData.highestWatchedCount.toLocaleString() + " 이상"
                : "0 이상"
            }
          />
        </div>
      </div>
    </div>
  );
};

export default ExpectAudience;
