import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5xy from "@amcharts/amcharts5/xy";
import { useEffect } from "react";

interface GraphProps {
  chartId: string;
  data: { name: string; community: any; news: any; portal: any }[];
}

const Graph = ({ chartId, data }: GraphProps) => {
  useEffect(() => {
    let root = am5.Root.new(chartId);

    // Set themes
    root.setThemes([am5themes_Animated.new(root)]);

    // Create chart
    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        focusable: true,
        // Disable panning and zooming with the mouse
        panX: false,
        panY: false,
        wheelX: "none", // Disable zooming with the mouse wheel on the X axis
        wheelY: "none", // Disable zooming with the mouse wheel on the Y axis
        pinchZoomX: false, // Disable pinch zooming on touch devices
      })
    );

    let colors = chart.get("colors");
    if (colors) {
      colors.set("step", 3);
    }

    // Create axes
    let xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        maxDeviation: 0.1,
        groupData: false,
        baseInterval: {
          timeUnit: "day",
          count: 1,
        },
        renderer: am5xy.AxisRendererX.new(root, {
          minGridDistance: 80,
          minorGridEnabled: true,
        }),
        tooltip: am5.Tooltip.new(root, {}),
      })
    );

    // Common Y axis
    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        maxDeviation: 1,
        renderer: am5xy.AxisRendererY.new(root, {}),
      })
    );

    function createSeries(fieldName: keyof (typeof data)[0], name: string) {
      let series = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: name,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: fieldName as string,
          valueXField: "date",
          tooltip: am5.Tooltip.new(root, {
            pointerOrientation: "horizontal",
            labelText: "{name}: {valueY}",
          }),
        })
      );

      series.data.processor = am5.DataProcessor.new(root, {
        dateFormat: "yyyy-MM-dd",
        dateFields: ["date"],
      });

      series.data.setAll(
        data.map((item) => ({
          date: new Date(item.name), // 날짜 변환
          [fieldName]: item[fieldName],
        }))
      );
      series.appear(1000);
    }

    // Create series for community, news, portal
    createSeries("community", "커뮤니티(TOP5)");
    createSeries("news", "NEWS(온라인)");
    createSeries("portal", "(블로그/카페)");

    // Add cursor
    let cursor = chart.set(
      "cursor",
      am5xy.XYCursor.new(root, {
        xAxis: xAxis,
        behavior: "none", // Disable dragging with the mouse
      })
    );
    cursor.lineY.set("visible", false);

    chart.appear(1000, 100);

    // Cleanup
    return () => {
      root.dispose();
    };
  }, [chartId, data]);

  return <div id={chartId} style={{ width: "100%", height: "500px" }} />;
};

export default Graph;
